import React from 'react';
import { Link, navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined, FacebookOutlined, InstagramOutlined, MenuOutlined, YoutubeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from '@react-spring/web';
import { toggleMobileNav } from '../store/appSlice';

const Nav = styled(animated.nav)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-image: linear-gradient(to bottom, @primary-color 0%, darken(@primary-color, 10%) 90%);
	background-position: center bottom 80px, center center;
	background-size: contain;
	background-repeat: no-repeat;
	transform-origin: 50% 100%;
	transition: all 0.2s ease-in-out;
	z-index: 1100;
	
	.languages {
		position: absolute;
		top: 10px;
		left: 10px;
		color: #FAFAFA;
		
		a {
			margin: 0 10px;
			color: #FAFAFA;
			font-size: 1.4rem;
			
			&:hover {
				color: #bf1e2e;
			}
		}
	}
	
	.socials {
		display: flex;
		justify-content: space-between;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 0 12px;
		
		h4 {
			color: #FAFAFA;
			margin-top: 24px;
			margin-bottom: 0;
		}
		
		a {
			margin: 0 10px;
			color: #FAFAFA;
			font-size: 1.8rem;
			
			&:hover {
				color: #bf1e2e;
			}
		}
	}
	
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		
		li {
			margin: 0;
			padding: 12px 0;
			text-align: center;
			
			a {
				font-size: 1.4rem;
				color: #FAFAFA;
				
				&:hover {
					color: rgba(240, 240, 240, 0.4);
				}
				
				&.active {
					text-decoration: underline;
					color: rgba(240, 240, 240, 0.4);
				}
			}
		}
	}
	
	@media (min-width: @screen-lg) {
		display: none;
	}
`;

const NavBar = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 80px;
	background: darken(@primary-color, 10%);
	z-index: 1200;
	
	& > div {
		position: fixed;
		bottom: 16px;
		left: 50%;
		padding: 6px 4px 2px 4px;
		transform: translateX(-50%);
		transform-origin: 0 50%;
		z-index: 12000;
		
		.anticon {
			font-size: 40px;
			color: #FAFAFA;
		}
		
		&.close {
			border: 1px solid #bf1e2e;
			border-radius: 28px;
		}
	}
	
	@media (min-width: @screen-lg) {
		display: none;
	}
`;

const MobileNav = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const { mobileNav } = useSelector(state => state.app);

	const changeLanguage = async lng => {
		await navigate(lng === 'fr' ? `/` : `/${lng}`, { replace: true });
		i18n.changeLanguage(lng);
	};

	const navAnim = useSpring({
		config: { duration: 250 },
		opacity: mobileNav ? 1 : 0,
		transform: mobileNav ? 'scale(1)' : 'scale(0)'
	});

	const openIcon = useSpring({
		transform: mobileNav ? 'scale(0) translateX(-50%)' : 'scale(1) translateX(-50%)'
	});

	const closeIcon = useSpring({
		transform: mobileNav ? 'scale(1) translateX(-50%)' : 'scale(0) translateX(-50%)'
	});

	return (
		<>
			<Nav style={navAnim}>
				{/*<div className="languages">
					<a href="#" onClick={() => changeLanguage('fr')}>fr</a> / <a href="#" onClick={() => changeLanguage('en')}>en</a>
				</div>*/}

				<div className="socials">
					<div>
						<h4>Domaine des Mattines</h4>
						<a href="https://www.facebook.com/domaine.mattines/" target="_blank"
						   rel="noreferrer"><FacebookOutlined/></a>
						<a href="https://www.instagram.com/domaine.des.mattines/" target="_blank"
						   rel="noreferrer"><InstagramOutlined/></a>
						<a href="https://www.youtube.com/channel/UCYhfn8Llwf08TYobORMT03A/" target="_blank"
						   rel="noreferrer"><YoutubeOutlined/></a>
					</div>
					<div style={{ textAlign: 'right' }}>
						<h4>Marché des Mattines</h4>
						<a href="http://www.facebook.com/lesmattines/" target="_blank"
						   rel="noreferrer"><FacebookOutlined/></a>
						<a href="https://www.instagram.com/mattines_gva/" target="_blank"
						   rel="noreferrer"><InstagramOutlined/></a>
					</div>
				</div>

				<ul>
					<li>
						<Link to="/" activeClassName="active" onClick={() => dispatch(toggleMobileNav())}>{t('HOME')}</Link>
					</li>
					<li>
						<Link to="/qui" activeClassName="active" partiallyActive={true} onClick={() => dispatch(toggleMobileNav())}>{t('WHO')}</Link>
					</li>
					<li>
						<Link to="/quoi" activeClassName="active" partiallyActive={true} onClick={() => dispatch(toggleMobileNav())}>{t('WHAT')}</Link>
					</li>
					<li>
						<Link to="/comment" activeClassName="active" partiallyActive={true} onClick={() => dispatch(toggleMobileNav())}>{t('HOW')}</Link>
					</li>
					<li>
						<Link to="/marche" activeClassName="active" partiallyActive={true} onClick={() => dispatch(toggleMobileNav())}>{t('MARKET')}</Link>
					</li>
					<li>
						<Link to="/automattines" activeClassName="active" partiallyActive={true} onClick={() => dispatch(toggleMobileNav())}>{t('AUTOMATTINES')}</Link>
					</li>
					<li>
						<Link to="/recettes" activeClassName="active" partiallyActive={true} onClick={() => dispatch(toggleMobileNav())}>{t('RECIPES')}</Link>
					</li>
					<li>
						<Link to="/medias" activeClassName="active" partiallyActive={true} onClick={() => dispatch(toggleMobileNav())}>{t('MEDIAS')}</Link>
					</li>
					<li>
						<Link to="/contact" activeClassName="active" partiallyActive={true} onClick={() => dispatch(toggleMobileNav())}>{t('CONTACT')}</Link>
					</li>
				</ul>
			</Nav>

			<NavBar>
				<animated.div style={openIcon}>
					<MenuOutlined onClick={() => dispatch(toggleMobileNav())}/>
				</animated.div>
				<animated.div className="close" style={closeIcon}>
					<CloseOutlined onClick={() => dispatch(toggleMobileNav())}/>
				</animated.div>
			</NavBar>
		</>
	);
}

export default MobileNav;