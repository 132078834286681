import React, { useEffect, useState } from 'react';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Img from 'gatsby-image';

const Nav = styled.nav`
	display: none;
	position: fixed;
	width: 100%;
	padding: 0 20px 0 40px;
	top: 0;
	box-shadow: ${props => props.shadow ? '0 1px 1px rgba(0, 0, 0, 0.03),0 2px 2px rgba(0, 0, 0, 0.03),0 4px 4px rgba(0, 0, 0, 0.03),0 8px 8px rgba(0, 0, 0, 0.03),0 16px 16px rgba(0, 0, 0, 0.03)' : 'none'};
	z-index: 100;
	background: #FAFAFA;
	transition: box-shadow 0.2s ease-in-out;
	
	.languages {
		text-align: right;
		padding-right: 10px;
		padding-top: 10px;
		
		a.active {
			color: #bf1e2e;
		}
	}
	
	ul {
		margin: 0;
		padding: 20px 20px 9px 20px;
		list-style-type: none;
		text-align: right;
		
		li {
			display: inline-block;
			
			a {
				color: @primary-color;
				font-size: 14px;
				padding: 10px;
				border-bottom: 1px solid @primary-color;
				
				&:hover {
					color: #bf1e2e;
				}
				
				&.active {
					color: @primary-color;
					border-color: #bf1e2e;
				}
			}
		}
	}
	
	@media (min-width: @screen-lg) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	@media (min-width: @screen-xl) {
		padding-left: 60px;
		
		ul {
			padding: 20px 20px 9px 20px;
			
			li {
				a {
					padding: 10px 15px;
					font-size: 16px;
				}
			}
		}
	}
	
	@media (min-width: @screen-xxl) {
		padding-left: 80px;
		
		ul {
			padding: 20px 40px 9px 40px;
			
			li {
				a {
					padding: 10px 20px;
					font-size: 18px;
				}
			}
		}
	}
`;

const MobileLogo = styled.div`
	width: 100%;
	height: auto;
	padding: 20px;
	text-align: center;
	
	img {
		max-width: 100%;
		height: 25vh;
	}
	
	@media screen and (min-width: @screen-lg) {
		display: none;
	}
`;

const DesktopNav = () => {
	const { t, i18n } = useTranslation();
	const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || 'fr';
	const [navShadow, setNavShadow] = useState(false);

	useEffect(() => {
		const handleScroll = () => setNavShadow(window.scrollY > 0);

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const changeLanguage = async lng => {
		await navigate(lng === 'fr' ? `/` : `/${lng}`, { replace: true });
		i18n.changeLanguage(lng);
	};

	const data = useStaticQuery(graphql`
	    query {
	      logoImg: file(relativePath: { eq: "logo.png" }) {
	        childImageSharp {
	          fixed(width: 190, height: 60) {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }
	    }
	`);

	return (
		<>
			<MobileLogo>
				<Img fixed={data.logoImg.childImageSharp.fixed} />
			</MobileLogo>

			<Nav shadow={navShadow}>
				<Img fixed={data.logoImg.childImageSharp.fixed} />

				<div>
					<div className="languages">
						{/*<a href="#" className={getCurrentLng() === 'fr' ? 'active': ''} onClick={() => changeLanguage('fr')}>fr</a>
						&nbsp;/&nbsp;
						<a href="#" className={getCurrentLng() === 'en' ? 'active': ''} onClick={() => changeLanguage('en')}>en</a>*/}
						&nbsp;
					</div>
					<ul>
						<li>
							<Link to="/" activeClassName="active">{t('HOME')}</Link>
						</li>
						<li>
							<Link to="/qui" activeClassName="active" partiallyActive={true}>{t('WHO')}</Link>
						</li>
						<li>
							<Link to="/quoi" activeClassName="active" partiallyActive={true}>{t('WHAT')}</Link>
						</li>
						<li>
							<Link to="/comment" activeClassName="active" partiallyActive={true}>{t('HOW')}</Link>
						</li>
						<li>
							<Link to="/marche" activeClassName="active" partiallyActive={true}>{t('MARKET')}</Link>
						</li>
						<li>
							<Link to="/automattines" activeClassName="active" partiallyActive={true}>{t('AUTOMATTINES')}</Link>
						</li>
						<li>
							<Link to="/recettes" activeClassName="active" partiallyActive={true}>{t('RECIPES')}</Link>
						</li>
						<li>
							<Link to="/medias" activeClassName="active" partiallyActive={true}>{t('MEDIAS')}</Link>
						</li>
						<li>
							<Link to="/contact" activeClassName="active" partiallyActive={true}>{t('CONTACT')}</Link>
						</li>
					</ul>
				</div>
			</Nav>
		</>
	);
};

export default DesktopNav;