import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Layout } from 'antd';
import { FacebookOutlined, InstagramOutlined, YoutubeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import '../theme/index.less';
import 'react-image-lightbox/style.css';

const Content = styled(Layout.Content)`
	background: #FAFAFA;
	padding: 0;
	
	@media screen and (min-width: @screen-lg) {
		min-height: calc(100vh - 427px);
		padding: 60px 0;
		margin-top: 100px;
	}
`;

const Footer = styled.footer`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 40px 20px;
	margin-bottom: 64px;
	background-color: #373c3f;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	color: #FAFAFA;
	
	& > div {
		text-align: center;
		margin-bottom: 40px;
	
		h3 {
			color: #FAFAFA;
		}
		
		h4 {
			color: #DDD;
			margin-top: 24px;
			margin-bottom: 0;
		}
		
		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
		
		a {
			color: rgba(240, 240, 240, 0.6);
			
			&:hover {
				color: #FAFAFA;
			}
			
			&.active {
				text-decoration: underline;
				color: #FAFAFA;
			}
		}
		
		&.copyright {
			order: 3;
			margin-bottom: 0;
		}
		
		&.socials {
			a {
				font-size: 1.8rem;
				margin: 0 20px;
			}
		}
	}
	
	@media (min-width: @screen-sm) {
		flex-direction: row;
		
		& > div {
			flex: 1 1 33%;
			margin-bottom: 0;
			
			&.copyright {
				order: 0;
			}
		}
	}
	
	@media (min-width: @screen-lg) {
		margin-bottom: 0;
	}
`;

const Container = ({ children }) => {
	const { t } = useTranslation();

	const data = useStaticQuery(graphql`
	    query {
	      logoImg: file(relativePath: { eq: "logo-footer.png" }) {
	        childImageSharp {
	          fixed(width: 190, height: 60) {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }
	    }
	`);

	return (
		<Layout>
			<Helmet>
				<link
					rel="stylesheet"
					href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
					integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
					crossOrigin=""
				/>
			</Helmet>

			<DesktopNav/>

			<Content>
				{children}
			</Content>

			<Footer>
				<div className="navigation">
					<h3>{t('Navigation')}</h3>
					<ul>
						<li>
							<Link to="/" activeClassName="active">{t('HOME')}</Link>
						</li>
						<li>
							<Link to="/qui" activeClassName="active" partiallyActive={true}>{t('WHO')}</Link>
						</li>
						<li>
							<Link to="/quoi" activeClassName="active" partiallyActive={true}>{t('WHAT')}</Link>
						</li>
						<li>
							<Link to="/comment" activeClassName="active" partiallyActive={true}>{t('HOW')}</Link>
						</li>
						<li>
							<Link to="/marche" activeClassName="active" partiallyActive={true}>{t('MARKET')}</Link>
						</li>
						<li>
							<Link to="/automattines" activeClassName="active" partiallyActive={true}>{t('AUTOMATTINES')}</Link>
						</li>
						<li>
							<Link to="/recettes" activeClassName="active" partiallyActive={true}>{t('RECIPES')}</Link>
						</li>
						<li>
							<Link to="/medias" activeClassName="active" partiallyActive={true}>{t('MEDIAS')}</Link>
						</li>
						<li>
							<Link to="/contact" activeClassName="active" partiallyActive={true}>{t('CONTACT')}</Link>
						</li>
					</ul>
				</div>
				<div className="copyright">
					<Img fixed={data.logoImg.childImageSharp.fixed} style={{ marginBottom: 40 }}/>

					<p>&copy; {(new Date()).getFullYear()}</p>
					
					<p style={{ marginTop: 40 }}>
						<small>
							<a href="https://nammu.ch/" target="_blank" rel="noreferrer">{t('DEVELOPED_BY')} nammu.ch</a>
						</small>
					</p>
				</div>
				<div className="socials">
					<h3>{t('SOCIAL_NETWORKS')}</h3>
					<h4>Domaine des Mattines</h4>
					<div>
						<a href="https://www.facebook.com/domaine.mattines/" target="_blank"
						   rel="noreferrer"><FacebookOutlined/></a>
						<a href="https://www.instagram.com/domaine.des.mattines/" target="_blank"
						   rel="noreferrer"><InstagramOutlined/></a>
						<a href="https://www.youtube.com/channel/UCYhfn8Llwf08TYobORMT03A/" target="_blank"
						   rel="noreferrer"><YoutubeOutlined/></a>
					</div>
					<h4>Marché des Mattines</h4>
					<div>
						<a href="http://www.facebook.com/lesmattines/" target="_blank"
						   rel="noreferrer"><FacebookOutlined/></a>
						<a href="https://www.instagram.com/mattines_gva/" target="_blank"
						   rel="noreferrer"><InstagramOutlined/></a>
					</div>
				</div>
			</Footer>

			<MobileNav/>
		</Layout>
	);
};

export default Container;
